<template>
  <div class="col">
    <div class="label">
      <fa-icon :icon="typeIcon(collector.type)" />
      {{collector.label}}
    </div>
    <div class="address">{{collector.address}}</div>
    <div class="directions">{{collector.directions}}</div>
    <div class="instructions">{{collector.instructions}}</div>
    <div class="status">{{collector.pickup_status}}</div>
  </div>
</template>

<script>
export default {
  name: 'Collector',
  props: {
    collector: Object,
  },
  methods: {
    typeIcon(type) {
      switch (type) {
        case 'house': return 'home';
        case 'cafe': return 'coffee';
        case 'collector': return 'dumpster';
        case 'company': return 'building';
        case 'school': return 'graduation-cap';
      }
      return type;
    }
  }
};
</script>

<style scoped lang="scss">
.col {
  padding: 0.2rem 0;
  height: 56px;
  border-bottom: 1px solid #aaa;
  line-height: 1.3;
}
.label {
    color: #484848;
    font-weight: bold;
}
.address {
  color: #015000;
}
.instructions {
    color: #e61111;
    display: block;
    margin-left: 24px;
    font-style: italic;
}

.status {
  position: absolute;
  right: 1rem; top: 0.3rem;
}
</style>
