<template>
  <div id="Collectors">
    <aside class="list">

      <div class="filters">
        <div class="checkboxes">
          <iCheckboxIconField 
            title="En attente"
            icon="clock"
            v-model="filter.pending" 
            />
          <span class="gl-labels">
            <iCheckboxIconField 
              v-for="type in types" :key="`type-${type}`"
              :title="type" 
              :icon="typeIcon(type)"
              v-model="filter.type[type]"
            />
          </span> 
        </div>
        <select v-model.number="filter.route_id">
          <option value="0">-- Circuit --</option> 
          <option value="-1">Aucun circuit</option> 
          <option disabled="disabled">------------</option> 
          <option value="12">Lundi Matin</option> 
          <option value="2">Lundi Soir</option> 
          <option value="13">Mardi Matin</option> 
          <option value="5">Mardi Soir</option> 
          <option value="14">Mercredi Matin</option> 
          <option value="3">Mercredi Soir</option> 
          <option value="7">Jeudi Matin</option> 
          <option value="6">Jeudi Soir</option> 
          <option value="11">Vendredi Matin</option> 
          <option value="4">Vendredi Soir</option> 
          <option disabled="disabled">------------</option> 
          <option value="all">Un circuit</option>
        </select> 
        <select v-model.number="filter.nb_pickups">
          <option value="0">-- Collectes --</option> 
          <option value="-1">Aucune collecte</option> 
          <option disabled="disabled">------------</option> 
          <option v-for="nb in filter.nbPickupSteps" :value="nb" :key="`step-${nb}`">Collectes &gt;= {{nb}}</option>
        </select> 
      </div>
      <iField v-model="filter.q" :label="false" field="iTextField" placeholder="Filtrer par nom, téléphone, email, ..." autocomplete="off" style="margin-bottom: 0.5rem" />

      <RecycleScroller class="scroller" :items="filteredCollectors" :item-size="58" key-field="id" v-slot="{ item }" >
        <Collector :collector="item" />
    </RecycleScroller>
    </aside>
    <div id="map" v-if="!Browser.isMobile()">
      <!-- The Map -->
      <MglMap :accessToken="accessToken" :mapStyle="mapStyle" :attributionControl="false" :hash="true" :tileBoundaries="true" @load="onMapLoad" >
        <MglFullscreenControl position="top-right" />
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
        <MglScaleControl position="bottom-left" />
      </MglMap>
    </div>
  </div>
</template>

<script>

import iCheckboxIconField from '@/components/widgets/fields/iCheckboxIconField';
import iField from '@/components/widgets/iField';

import { MglMap, MglNavigationControl, MglGeolocateControl, MglFullscreenControl, MglScaleControl } from "vue-mapbox";
import Mapbox from "mapbox-gl";
Mapbox.setRTLTextPlugin('/js/mapbox-gl-rtl-text.js');
import { AllLayers } from "../assets/layers";
import { StylesControl, addMobilePitchGesture, LanguageSelectorControl } from "../assets/p";
import config from '../config';
import Collector from "@/components/Collector";
const { apiUrl } = config;
import Browser from "@/helpers/Browser";
let geoJson = {
    'type': 'FeatureCollection',
    'features': []
}; 


export default {
  components: {
    iField,
    iCheckboxIconField,
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl,
    Collector,
  },
  data() {
    return {
      accessToken: 'pk.eyJ1Ijoic2VsaW1hY2hvdXIiLCJhIjoiY2pjZW0weGoxMWZkZDJ3cGVmYmZkbHVreiJ9.0dFhFtJJUEN2ziihdjzNwQ',
      mapStyle: 'mapbox://styles/selimachour/ckbt1w6610h131iohq2mvsq1w',
      // mapStyle: AllLayers['where-streets'],
      mapbox: Mapbox,
      apiUrl,
      Browser,
      routes: null,
      route: null,
      modal: 'routes',
      types: ["house","cafe","collector","company","school"],
      colors: {"2":"#216fe4","3":"#ff3636","4":"#008400","5":"#e78d0d","6":"#9e23d7","7":"#3bae7c","11":"#000000","12":"#00ecff","13":"#ffc43e","14":"#ff6594"},
      allCollectors: [],
      filteredCollectors: [],
      markers: {},
      filter: {
          nbPickupSteps: [1, 3, 5, 10, 40, 70, 100, 130, 160, 190, 220],
          type: {},
          route_id: 0,
          nb_pickups: 0,
          pending: false,
          q: ''
      }
    };
  },
  created() {
    this.routes = this.$store.state.routes;
    this.$api.get('/collectors').then(j => {
      this.allCollectors = j.data;
    })
  },
  methods: {
    onMapLoad(event) {
      const map = event.map;
      this.map = event.map;

      // faster scroll with mouse wheel
      map.scrollZoom.setWheelZoomRate(1/100);
      map.setCenter([10.32, 36.88]);
      map.setZoom(12);

      // with custom styles:
      map.addControl(new StylesControl({ styles: Object.values(AllLayers), }), 'top-left');
      addMobilePitchGesture(map);
      map.addControl(new LanguageSelectorControl());

      event.map.on('click', function() {
      });

      map.addLayer({
        'id': 'collectors',
        'type': 'circle',
        'source': {
            'type': 'geojson',
            'data': geoJson
        },
        'paint': {
            'circle-radius': [ "interpolate", [ "exponential", 1.5 ], [ "zoom" ], 9, 1, 17, 5 ],
            'circle-color': ['get', 'color']
        }
    });
    // add collector labels
    map.addLayer({
        'id': 'collectors-labels',
        'type': 'symbol',
        'source': {
            'type': 'geojson',
            'data': geoJson
        },
        'layout': {
            'text-field': ['get', 'label'],
            'text-variable-anchor': ['bottom'],
            'text-radial-offset': 0.5,
            'text-font': ['Open Sans Bold'],
            'text-size': [ "interpolate", [ "exponential", 1.5 ], [ "zoom" ], 13, 9, 19, 12 ],
        },
        'paint': {
            'text-halo-color': '#ffffff',
            'text-halo-width':  1,
            'text-color': ['get', 'color'],
        },
        'minzoom': 14
    });
    },
    createPickup : function(c) {
        const scope = this;
        $.ajax({
            url: ROOT_URL + 'collector/quick-create',
            data: { 
                collector_id: c.id
            },
            dataType: 'json',
            success: function(j) {
                scope.allCollectors = scope.allCollectors.map(c => (c.id === j.id) ? j : c);
            }
        });
    },
    updatePickup: function(c, event) {
        var scope = this;
        var select = event.target;
        var option = select.options[select.selectedIndex]
        $.ajax({
            url: ROOT_URL + 'collector/quick-update',
            data: { 
                collector_id: c.id, 
                status: option.dataset.status, 
                log: option.dataset.log 
            },
            dataType: 'json',
            success: function(j) {
                // update collector in array
                let idx = scope.allCollectors.findIndex((c) => c.id === j.id);
                Vue.set(scope.allCollectors, idx, j);
            }
        });
    },
    centerMarker: function(c) {
        map.flyTo({
            center: [ c.lon, c.lat ],
            zoom: 18,
            essential: true
        });
    },
    filterList() {
            var terms, reg = [], types = [];
            
            terms = this.filter.q.trim().replace(/ +/, ' ').split(' ');
            for (var i = 0, t; t = terms[i]; i++) {
                reg.push(new RegExp(t, 'i'));
            }

            if (this.filter.type) 
                for (var t in this.filter.type) 
                    if (this.filter.type[t]) types.push(t);

            this.filteredCollectors = this.allCollectors.filter(c => {
                var show = true;

                if (this.filter.pending && (c.pickup_status != 'pending' && !c.always) ) show = false;
                else if (types.length && types.indexOf(c.type) < 0) show = false;
                else if ( (this.filter.route_id == 'all') && (!c.route_id) ) show = false;
                else if ( (this.filter.route_id == 'none') && (c.route_id) ) show = false;
                else if ( (this.filter.route_id) && (c.route_id != this.filter.route_id)) show = false;
                else if ( (-1 == this.filter.nb_pickups) && (c.nb_pickups > 0)) show = false;
                else if ( this.filter.nb_pickups && (c.nb_pickups < this.filter.nb_pickups) ) show = false;
                else if (reg.length) {
                    if (!c.q) 
                        c.q = [c.label, c.tel ? c.tel.replace(/ /g, '') : '', c.email, c.address].join(' ');
                    
                    for (var k = 0, r; r = reg[k]; k++) {
                        if (!r.test(c.q)) {
                            show = false;
                            break;
                        }
                    }
                }

                return show;
            });

            const features = this.filteredCollectors.map(c => {
                return {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [ c.lon, c.lat ]
                    },
                    'properties': {...c, color: this.colors[c.route_id ? c.route_id : 0] }
                }
            });
            geoJson.features = features;
            if (this.map) {
              if (this.map.getSource('collectors')) this.map.getSource('collectors').setData(geoJson);
              if (this.map.getSource('collectors-labels')) this.map.getSource('collectors-labels').setData(geoJson);
            }
    },
    typeIcon(type) {
      switch (type) {
        case 'house': return 'home';
        case 'cafe': return 'coffee';
        case 'collector': return 'dumpster';
        case 'company': return 'building';
        case 'school': return 'graduation-cap';
      }
      return type;
    }
  },
  watch: {
    filter: {
        immediate: true,
        deep: true,
        handler: function() { this.filterList(); }
    },
    allCollectors: {
        immediate: true,
        deep: true,
        handler: function() { this.filterList(); }
    },
  },
  computed: {
    cIds: function() {
        return this.filteredCollectors.map(c => c.id).join(',');
    },
    nbPending() {
      return this.allCollectors.length;
    }
  }
};
</script>

<style>
@import "../../node_modules/mapbox-gl/dist/mapbox-gl.css";
@import "../assets/sass/mapbox.scss";
</style>
<style scoped lang="scss">
#Collectors {
  position: absolute;
  background: #ecf0f5;
  left: 0; right: 0; top: 0;bottom: 0;
}

#map {
  position: absolute;
  right: 10px;
  left: 500px;
  top: 10px;
  bottom: 10px;
}

.filters {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5em;

  & ::v-deep select {
    background: transparent;
    padding: 0.2rem;
  }
}

.checkboxes {
  white-space: nowrap;
}

.filters ::v-deep  svg {
  margin: 5px 3px;
  transform: scale(1.1) translateY(1px);
}

aside {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  width: 500px;
  top: 0;
  bottom: 0;
  font-size: 12px;
  padding: 1rem;
}

.scroller {
  overflow: auto;
}


</style>